// eslint-disable-next-line import/no-extraneous-dependencies
import { ObjectId } from 'bson';

export interface FeatureFlag {
  key: string;
  value: string;
}

export interface VariantBase {
  name: string;
  trafficPercentage: number;
  isControl: boolean;
  featureFlags?: Array<FeatureFlag>;
  isWinner?: boolean;
}

export interface Variant extends VariantBase {
  _id: string;
}

export interface UIVariant extends VariantBase {
  _id?: string;
}

export interface TrackingMetric {
  _id: string;
  isPrimary: boolean;
}

export interface Tag {
  name: string;
  scope: Scope;
  scopePercentage: number;
}

export enum Scope {
  USER = 'USER',
  GROUP = 'GROUP',
  ORG = 'ORG',
  HOST = 'HOST',
  CLUSTER = 'CLUSTER',
  TENANT = 'TENANT',
}

export const enum RunningStatus {
  DRAFT = 'DRAFT',
  LIVE = 'LIVE',
  COOLDOWN = 'COOLDOWN',
  LAUNCHED = 'LAUNCHED',
  COMPLETE = 'COMPLETE',
  DELETED = 'DELETED',
}

export const enum RunningStatusLegacy {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  PAUSED = 'PAUSED',
}

export enum DecisionStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  NEUTRAL = 'NEUTRAL',
}

export const enum ExpType {
  AB = 'AB',
  MAB = 'MAB',
  CMAB = 'CMAB',
  HOLDOUT = 'HOLDOUT',
}

export enum MabPolicy {
  EPSILON_GREEDY = 'Epsilon Greedy',
  THOMPSON_SAMPLING = 'Thompson Sampling',
}

export interface ExperimentConfig {
  // id is ObjectId in db response but is string when in a request
  _id?: ObjectId | string;
  name: string;
  variants: Variant[] | UIVariant[];
  scopePercentage: number;
  scope: Scope;
  description?: string;
  tag: string;
  runningStatus: RunningStatus | RunningStatusLegacy;
  decisionStatus?: DecisionStatus;
  faultTolerance?: number;
  trackingMetrics: TrackingMetric[];
  startDate?: Date;
  cooldownStartDate?: Date;
  launchDate?: Date;
  endDate?: Date;
  expType: ExpType;
  mabPolicy?: MabPolicy;
  jiraLink: string;
  experimentDocLink?: string;
  designLink?: string;
  variantSampleSize?: number;
  targetDuration?: number;
  allocationPoints?: string[];
}

export type ExperimentConfigUpsert = Omit<ExperimentConfig, 'expType' | 'scopePercentage'> & { expType?: ExpType; scopePercentage?: number};
