// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react';
import { getEnvFromUrl } from './environment';
import { ENVIRONMENT } from './constants';
var SENTRY_DATA_SOURCE_NAME = 'https://dae2eafc79edcdd836adbfa7b60faf1a@o4504991346720768.ingest.us.sentry.io/4507697932730368';
/**
 * Sends the error to the Sentry service.
 */
export var sendError = function (error) {
    if (typeof error === 'string') {
        Sentry.captureException(new Error(error));
    }
    else {
        Sentry.captureException(error);
    }
};
/**
 * Initializes Sentry
 */
export var initSentry = function () {
    var appEnvironment = getEnvFromUrl();
    var sentryDataSourceName = SENTRY_DATA_SOURCE_NAME;
    var sentryOptions = {
        dsn: sentryDataSourceName,
        environment: appEnvironment,
        sampleRate: appEnvironment === ENVIRONMENT.LOCAL ? 0 : 1.0,
    };
    if (appEnvironment === ENVIRONMENT.PROD && process.env.NEXT_PUBLIC_SENTRY_RELEASE) {
        sentryOptions.release = process.env.NEXT_PUBLIC_SENTRY_RELEASE;
    }
    Sentry.init(sentryOptions);
};
