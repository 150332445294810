var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Router from 'next/router';
import Button from '@leafygreen-ui/button';
var Logo = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-top: 13px;\n"], ["\n  padding-top: 13px;\n"])));
var LogoMark = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 180px;\n"], ["\n  width: 180px;\n"])));
var Header = styled.header(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: sticky;\n  width: 100%;\n  top: 0;\n  z-index: 20;\n  display: flex;\n  border-bottom: 1px solid #e5e5e5;\n  height: 86px;\n  line-height: 80px;\n  font-size: 28px;\n  background-color: white;\n"], ["\n  position: sticky;\n  width: 100%;\n  top: 0;\n  z-index: 20;\n  display: flex;\n  border-bottom: 1px solid #e5e5e5;\n  height: 86px;\n  line-height: 80px;\n  font-size: 28px;\n  background-color: white;\n"])));
var HeaderContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    display: flex;\n    width 100%;\n    justify-content: space-between;\n    padding: 0 25px;\n"], ["\n    display: flex;\n    width 100%;\n    justify-content: space-between;\n    padding: 0 25px;\n"])));
var LeftWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  cursor: pointer;\n"], ["\n  display: flex;\n  cursor: pointer;\n"])));
var ButtonWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  column-gap: 10px;\n"], ["\n  display: flex;\n  align-items: center;\n  column-gap: 10px;\n"])));
export default (function () { return (<Header>
    <HeaderContent>
      <LeftWrapper onClick={function () { return Router.push('/'); }}>
        <Logo>
          <LogoMark src="/img/logomark.svg"/>
        </Logo>
      </LeftWrapper>
      <ButtonWrapper>
        <Button variant="primary" data-testid="add-exp-button" onClick={function () { return Router.push('/config'); }}>
          Create New Experiment
        </Button>
        <Button data-testid="allocation-points-button" onClick={function () { return Router.push('/allocationPoints'); }}>
          Manage Allocation Points
        </Button>
      </ButtonWrapper>
    </HeaderContent>
  </Header>); });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
