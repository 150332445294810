var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Realm from 'realm-web';
import { ENV_HOSTS, REALM_DATA } from '../utils/constants';
import { sendError } from '../utils/error-tracker';
var getEnvHostFromUrl = function () {
    var envHost = ENV_HOSTS.DEV;
    var windowHost = window.location.hostname;
    if (Object.values(ENV_HOSTS).includes(windowHost)) {
        envHost = windowHost;
    }
    return envHost;
};
var authenticateUser = function () { return __awaiter(void 0, void 0, Promise, function () {
    var envHost, app, apiKey, credentials, err_1, credentials, user, err_2;
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                envHost = getEnvHostFromUrl();
                app = new Realm.App({
                    id: REALM_DATA[envHost].REALM_APP_ID,
                    baseUrl: REALM_DATA[envHost].REALM_BASE_URL,
                    skipLocationRequest: true,
                });
                if (!(envHost === ENV_HOSTS.LOCAL)) return [3 /*break*/, 5];
                apiKey = void 0;
                if (process.env.NEXT_PUBLIC_LOCAL_APP_ID) {
                    apiKey = (_a = process.env.NEXT_PUBLIC_LOCAL_API_KEY) !== null && _a !== void 0 ? _a : '';
                }
                else {
                    apiKey = (_b = process.env.NEXT_PUBLIC_DEV_API_KEY) !== null && _b !== void 0 ? _b : '';
                }
                credentials = Realm.Credentials.apiKey(apiKey);
                _c.label = 1;
            case 1:
                _c.trys.push([1, 3, , 4]);
                return [4 /*yield*/, app.logIn(credentials)];
            case 2: return [2 /*return*/, _c.sent()];
            case 3:
                err_1 = _c.sent();
                sendError(err_1);
                return [2 /*return*/, err_1];
            case 4: return [3 /*break*/, 10];
            case 5:
                _c.trys.push([5, 9, , 10]);
                credentials = Realm.Credentials.function({});
                return [4 /*yield*/, app.logIn(credentials)];
            case 6:
                user = _c.sent();
                return [4 /*yield*/, user.functions.updateUserData()];
            case 7:
                _c.sent();
                return [4 /*yield*/, user.refreshCustomData()];
            case 8:
                _c.sent();
                return [2 /*return*/, user];
            case 9:
                err_2 = _c.sent();
                sendError(err_2);
                return [2 /*return*/, err_2];
            case 10: return [2 /*return*/];
        }
    });
}); };
export default authenticateUser;
